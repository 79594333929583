import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios
import './Styles.css'; // Import the CSS for this page

function LoginPage() {
  useEffect(() => {
    document.title = 'Login Page'; // Change this to whatever you want to display on the browser tab
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // For handling errors
  const [successMessage, setSuccessMessage] = useState(''); // For handling success messages

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send a POST request to the backend API
      const response = await axios.post('https://api.nicholasgottschalk.com/login', {
        email,
        password,
      });

      // Handle the response from the server
      console.log(response.data);
      setSuccessMessage('Login successful!'); // Set success message
      setError(''); // Clear any previous error
    } catch (err) {
      // Handle error response
      console.error(err);
      setError('Login failed. Please check your credentials.'); // Set error message
      setSuccessMessage(''); // Clear any previous success message
    }
  };

  return (
    <div className="login-page">
      <header className="login-header">
        <div className="login-form-container">
          <h1>Login</h1>
          {error && <p className="error">{error}</p>} {/* Display error message */}
          {successMessage && <p className="success">{successMessage}</p>} {/* Display success message */}
          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Login</button>
          </form>
        </div>
      </header>
    </div>
  );
}

export default LoginPage;
